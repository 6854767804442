import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import createViewModel from 'src/lib/view-model/login-view-model'
import useErrorToast from 'src/lib/hooks/use-error-toast'
import apiClient from 'src/lib/api-client'
import Loading from 'src/components/Loading'
import { ErrorBoundary } from '@sentry/nextjs'
import { LoginViewModel } from 'src/lib/view-model/login-view-model'
import LoginForm from 'src/components/LoginForm'
import { AuthContext } from 'src/context/AuthContext'

type Props = {
  viewModel: LoginViewModel
  handleLogin(name: string, password: string, remember: boolean): void
  isLoading: boolean
}

const CreateLoginPageView = ({ viewModel, handleLogin, isLoading }: Props) => {
  return isLoading ? (
    <Loading />
  ) : (
    <LoginForm
      handleLogin={handleLogin}
      lastSavedInvalidAttributes={viewModel.lastSavedInvalidAttributes}
    />
  )
}

const LoginPage = () => {
  const { isReady, push } = useRouter(),
    [error, setError] = useState<Error>(),
    showErrorToast = useErrorToast(),
    [viewModel, setViewModel] = useState<LoginViewModel>(createViewModel()),
    [isLoading, setIsLoading] = useState<boolean>(false),
    { authenticated, setAuthenticated } = useContext(AuthContext),
    emptyContent = useMemo(
      () => (
        <CreateLoginPageView
          viewModel={createViewModel()}
          handleLogin={() => {}}
          isLoading={false}
        />
      ),
      []
    )

  useEffect(() => {
    if (authenticated) {
      push('/report/new')
    }
  }, [authenticated, push])

  const handleLogin = useCallback(
    async (name: string, password: string, remember: boolean) => {
      try {
        setIsLoading(true)
        setViewModel(createViewModel({ name, password, remember }))
        const { token } = await apiClient.authSignIn(name, password)
        if (token) {
          setAuthenticated(token)
          push('/report/new')
        }
      } catch (e) {
        if (e instanceof Error) {
          setError(e)
        } else {
          throw e
        }
      } finally {
        setIsLoading(false)
      }
    },
    [push, setAuthenticated, setError, setIsLoading, setViewModel]
  )
  return (
    <ErrorBoundary fallback={emptyContent} onError={showErrorToast}>
      <CreateLoginPageView
        viewModel={viewModel}
        handleLogin={handleLogin}
        isLoading={!isReady || isLoading}
      />
    </ErrorBoundary>
  )
}

export default LoginPage
