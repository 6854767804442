type loginAttrs = { name: string; password: string; remember: boolean }

export type LoginViewModel = {
  lastSavedInvalidAttributes: loginAttrs | undefined
}

export default function createModel(
  lastSavedInvalidAttributes: loginAttrs | undefined = undefined
): LoginViewModel {
  return {
    lastSavedInvalidAttributes: lastSavedInvalidAttributes,
  }
}
