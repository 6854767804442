import styles from './LoginForm.module.scss'
import formStyles from 'src/lib/@socialprofiler/ui-kit/Form.module.scss'
import { useTranslation } from 'react-i18next'
import { FormEvent, useEffect, useState } from 'react'
import ColoredButton from '@socialprofiler/ui-kit/ColoredButton'
import FormField from '@socialprofiler/ui-kit/FormField'

type Props = {
  handleLogin(name: string, password: string, remember: boolean): void
  lastSavedInvalidAttributes?: { name: string; password: string; remember: boolean }
}

const LoginForm = ({ handleLogin, lastSavedInvalidAttributes }: Props) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [remember, setRemember] = useState<boolean>(false)
  const reset = () => {
    setName('')
    setPassword('')
    setRemember(false)
  }
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (name != '' && password != '') {
      handleLogin(name, password, remember)
    } else {
    }
    reset()
  }
  useEffect(() => {
    if (lastSavedInvalidAttributes) {
      const { name, password, remember } = lastSavedInvalidAttributes!
      setName(name)
      setPassword(password)
      setRemember(remember)
    }
  }, [lastSavedInvalidAttributes])
  return (
    <div>
      <form className={`${formStyles.boxWithForm} box`} onSubmit={handleSubmit}>
        {/*<h1 className={styles.header}>{t('headerGettingUserForm')}</h1>*/}
        <FormField
          className=""
          label={t('LoginFormNameFieldLabel')}
          type="text"
          placeholder={t('LoginFormNameFieldPlaceholder')}
          value={name}
          onChange={(event) => setName(event.target.value)}
          errorMessage={t('LoginFormWrongNameMessage')}
          showErrorMessage={
            !!lastSavedInvalidAttributes && name === lastSavedInvalidAttributes.name
          }
        />
        {/*<FormField*/}
        {/*  label={t('LoginFormRememberFieldLabel')}*/}
        {/*  type="checkbox"*/}
        {/*  placeholder={t('LoginFormRememberFieldPlaceholder')}*/}
        {/*  value=""*/}
        {/*  checked={remember}*/}
        {/*  onChange={(event) => {*/}
        {/*    setPassword(event.target.value)*/}
        {/*  }}*/}
        {/*/>*/}
        <FormField
          className=""
          label={t('LoginFormPasswordFieldLabel')}
          type="password"
          placeholder={t('LoginFormPasswordFieldPlaceholder')}
          value={password}
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />

        <ColoredButton type="submit" className={styles.blackButton}>
          {t('LoginFormSigninButtonText')}
        </ColoredButton>
      </form>
    </div>
  )
}

export default LoginForm
