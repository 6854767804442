import { ChangeEvent, useState } from 'react'
import styles from './FormField.module.scss'

type FormFieldProps = {
  className?: string
  label: string
  type: string
  placeholder: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  pattern?: string
  required?: boolean
  checked?: boolean
  errorMessage?: string
  showErrorMessage?: boolean
}

const FormField = ({
  className,
  label,
  type,
  value,
  checked,
  onChange,
  pattern,
  required,
  errorMessage,
  showErrorMessage,
}: FormFieldProps) => {
  const [focused, setFocused] = useState(true)
  return (
    <div className={`field ${styles.field} ${className}`}>
      <label className={`label ${styles.label}`}>{label}</label>
      <div className="control">
        <input
          className={`input ${styles.input} ${
            !focused && ((required && value && value !== '') || !required)
              ? styles.isNotFocused
              : ''
          } ${showErrorMessage ? styles.showErrorMessage : ''}`}
          type={type}
          value={value}
          checked={!!checked}
          onChange={onChange}
          pattern={pattern}
          onBlur={() => setFocused(false)}
          required={required}
        />
        {errorMessage && (
          <div className={styles.errorMessage}>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default FormField
